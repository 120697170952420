<template>
  <div class="container">
    <HeaderIndex @salvedPage="dataPages"/>

    <div class="content-cards">
      <div class="box-total justify-content-center mb-3">
        <p class="total-vendas">
          Total de Fanpages:
          <span>{{ rows }}</span>
        </p>
      </div>
      <b-card
        v-for="n of 3"
        :key="n"
        class="mb-2 card-fanpage shine"
        style="height: 468px;"
        v-show="loadingCards"
      >
      </b-card>
      <b-card
        v-for="page of fanpages"
        :key="page.id"
        :title="'#0' + page.id + ' ' + page.fb_fanpage_title"
        :img-src="page.fb_link_img"
        img-alt="Image"
        img-top
        tag="article"
        class="mb-2 card-fanpage"
        @click="$router.push('lead-ads/fanpage/'+page.id)"
      >
        <div class="pl-4 ml-3">
          <div class="content-info">
            <div id="content-status">
              <div class="status" :class="`status bg-${page.status.type}`"></div>
            </div>
            <span class="text-data-info" :class="`status-text-${page.status.type}`">{{ page.status.message }}</span>
          </div>
          <div class="leads-cap content-info">
            <img :src="imgPeople" alt="Pessoa" />
            <span class="text-data-info"
              >{{ getNameLeads(page.total_leads) }}</span
            >
          </div>
          <div class="last-capture-made content-info">
            <img :src="imgRelogio" alt="Relógio" width="16" height="18" />
            <span class="text-data-info">{{
              getTextCapture(page.last_catch)
            }}</span>
          </div>
          <div class="integrations content-info">
            <img :src="imgIntegration" alt="Integração" />
            <span class="text-data-info"
              >{{ getNameIntegrations(page.integrations) }}</span
            >
          </div>
        </div>
      </b-card>
      <b-row
        v-if="fanpages.length === 0 && !loadingCards"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma página foi encontrada</p>
      </b-row>
      <VFacebookLoginScope :app-id="getIdApp" />
    </div>
    <Paginate
      v-if="lastPage && lastPage > 1"
      :totalPages="lastPage"
      :activePage="numberPage"
      @to-page="dataPages"
    />
    
  </div>
</template>
<script>
import Paginate from "@/components/Paginate";
import LeadAdService from "@/services/resources/LeadAdService.js";
const LeadAdApiService = LeadAdService.build();
import HeaderIndex from './HeaderIndex.vue'
import {mapMutations} from 'vuex'

import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";

export default {
  name: "Listagem",
  components: {
    HeaderIndex,
    Paginate,
    VFacebookLoginScope,
  },
  data: () => ({
    fanpages: [],
    valuesForPaginate: null,
    imgPeople: require("@/assets/icons/people.svg"),
    imgIntegration: require("@/assets/icons/lightning.svg"),
    imgRelogio: require("@/assets/icons/relogio.svg"),
    rows: 0,
    perPage: 0,
    numberPage: 0,
    lastPage: 0,
    loadingCards: false,
  }),
  computed: {
    getIdApp: () => process.env.FACEBOOK_APP_ID,
    getPerPage: function () {
      return this.perPage;
    },
    getNameIntegrations: function(){
      return (total)=>{
        return total > 1 ? total+' Integrações' : total+' Integração'
      }
    },
    getNameLeads: function(){
      return (total)=>{
        return total > 1 ? total+' Leads capturado' : total+' Lead capturado'
      }
    },
  },
  created: async function () {
    let responseApi = await this.dataPages();
    if(responseApi.error){
      this.$bvToast.toast("Falha ao carregar dados de fanpages", {
        title: "Fanpage",
        variant: "danger",
        autoHideDelay: 3000,
        appendToast: true,
      });
    }
    this.rows = responseApi.total;
    this.lastPage = responseApi.last_page;
    this.perPage = responseApi.per_page;
  },
  methods: {
    ...mapMutations(['loading']),
    
    getTextCapture: (lastCatch) =>
      !lastCatch ? "Não há capturas" : "Últíma captura feita há " + lastCatch,
    
    async dataPages(page) {
      this.loadingCards = true
      if(!page && !this.numberPage){
        this.numberPage = 1
      }else if(page !== undefined){
        this.numberPage = page;
      }
      let responseApi = await LeadAdApiService.read("?page="+this.numberPage);
      this.fanpages = responseApi.data;
      this.rows = responseApi.total;
      this.loadingCards = false
      return responseApi;
    },
  },
};
</script>

<style >
.card-img-top {
  width: 100%;
  height: 217.91px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.content {
  padding-top: 35px;
}

.content-cards {
  display: flex;
  gap: 2%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.card {
  border-radius: 5px 5px 0px 0px !important;
  margin-bottom: 28px !important;

}
.card-body,
.card-fanpage {
  width: 32%;
  border: 0.1px solid var(--white-medium) !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  padding-left: 0;
  cursor: pointer;
}

.card-body {
  width: 100% !important;
  border: none !important;
}

.card-title {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: var(--gray01);
  margin-left: 38px;
}

.card-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  color: var(--gray01);
  margin-left: 38px;
}

.content-info {
  margin-top: 21.19px;
}
#content-status {
  width: 16px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  width: 10px;
  height: 10.02px;
  border-radius: 50px;
}

.content-info {
  margin-top: 21.19px;
  display: flex;
  align-items: center;
}

.text-data-info {
  margin-left: 26px;
}

.status-text-error, .status-text-warning, .status-text-success{
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}

.status-text-error{
  color: red;

}
.status-text-warning{
  color: var(--orange);
}
.status-text-successes{
  color: var(--greenn);
}

.bg-successes{
  background-color: var(--greenn);
}
 .bg-error{
   background-color: red;
 }

</style>