<template>
  <div>
    <BaseModal :idModal="idModal" :name="idModal" :id="idModal" size="md" title="Associar fanpage">
      <div class="container">
        <div class="row px-4">
          <div class="col-12">
            <p class="title-step">01- Faça o login</p>
          </div>
          <div class="col-12">
            <LoginFacebook @loged="loged" :isLoged="isLoged" />
          </div>

          <div class="col-12">
            <p class="title-step mt-2">02- Selecione a fanpage</p>
          </div>
          <div class="col-12 list-fanpage-select">
            <multiselect
              v-model="page"
              :options="options"
              :show-labels="false"
              :searchable="false"
              selectedLabel="Selecionado"
              label="name"
              track-by="name"
              @input="selectFanpage"
              :disabled="this.options.length === 0"
            >
              <template slot="option" slot-scope="props"
                ><img
                  class="option__image"
                  :src="props.option.picture.data.url"
                  :alt="props.option.name"
                />
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
            <div class="login-again"><a @click="removeAccount()">Logar novamente</a></div>
          </div>
        </div>
      </div>
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" class="mr-3" @click="cancel">
          Fechar
        </BaseButton>
        <BaseButton variant="primary" @click="create" :disabled="disabledBtn">
          Adicionar Fanpage
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>
<script>
import LoginFacebook from "./LoginFacebook.vue";
import { mapMutations } from "vuex";

import LeadAdService from "@/services/resources/LeadAdService.js";
const LeadAdApiService = LeadAdService.build();
import Multiselect from "vue-multiselect";

import UserLoggedService from "@/services/resources/UserLoggedService";
const serviceMe = UserLoggedService.build();

export default {
  name: "ModalAddFanPage",
  props: {
    idModal: String,
  },
  components: {
    LoginFacebook,
    Multiselect,
  },
  data: () => ({
    isLoged: false,
    pages: [],
    page: { name: "" },
    disabledBtn: true,
    IdsFanpage: [],
    dataUser: [],
    options: [],
  }),
  created: async function () {
    let idsPage = await LeadAdApiService.read("");
    this.IdsFanpage.push(
      ...idsPage.data.map(item => item.fb_fanpage_id)
    );
  },
  watch: {
    IdsFanpage: function () {
      if (this.dataUser.data) {
        this.options = [];
        this.options = this.getPages(this.dataUser.data, true);
        this.isLoged = false;
      }
    },
  },
  methods: {
    loged(dataUser) {
      if (dataUser) {
        this.dataUser = dataUser;
        this.options = this.getPages(dataUser.data);
        this.isLoged = true;
      }
    },
    removeAccount(){      
      this.dataUser = [];
      this.isLoged = false;
      this.disabledBtn = true;
      this.options = [],
      Cookies.remove('_dd_s');
      Cookies.remove('c_user');
      this.$bvToast.toast("Campos limpos!", {
          title: "Fanpage",
          variant: "success",
          autoHideDelay: 3000,
          appendToast: true,
        });
    },
    getPages(dataUser, inWatch = false) {
      let pages = dataUser
        .map((item) => {
          return !this.IdsFanpage.includes(+item.id) ? item : undefined;
        })
        .filter((item) => item);
      if (!pages[0] && !inWatch && dataUser.length) {
        this.$bvToast.toast("Nenhuma fanpage nova foi encontrada", {
          title: "Fanpage",
          variant: "warning",
          autoHideDelay: 3000,
          appendToast: true,
        });
      }else if(!pages[0] && !inWatch){
        this.$bvToast.toast("Nenhuma fanpage foi encontrada", {
          title: "Fanpage",
          variant: "danger",
          autoHideDelay: 3000,
          appendToast: true,
        });
      }
      return pages;
    },
    async selectFanpage(page) {
      this.disabledBtn = true;
      let check = await LeadAdApiService.read("/check-used-fanpage/" + page.id);
      if (check.used) {
        this.page = { name: "" };
        this.$bvToast.toast("Fanpage já integrada!", {
          title: "Fanpage",
          variant: "danger",
          autoHideDelay: 3000,
          appendToast: true,
        });
        return false;
      }
      
      if (!page) {
        this.page = { name: "" };
      } else {
        this.page = page;
      }
      this.disabledBtn = page ? false : true;
    },
    async create() {
      if (this.page) {
        this.disabledBtn = true;
        const tenant_id = this.$store.getters.user.user.tenant_id;
        let user = await serviceMe.search();
        const data = {
          tenant_id: tenant_id,
          fb_fanpage_id: +this.page.id,
          fb_fanpage_title: this.page.name,
          fb_fanpage_token: this.page.access_token,
          created_by: user.user.ID,
          fb_link_img: "",
        };
        try {
          let fanpage = await LeadAdApiService.create(data);
          if (!fanpage.error) {
            this.IdsFanpage.push(fanpage.fb_fanpage_id);
            this.$emit("salvedPage");
            this.$bvModal.hide(this.idModal);
            this.isLoged = false;
            this.page = { name: "" };
            return;
          }
          this.$bvToast.toast(fanpage.error, {
            title: "LeadAds",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        } catch (e) {
          this.$bvToast.toast("Falha ao salvar fanpage", {
            title: "Fanpage",
            variant: "danger",
            autoHideDelay: 3000,
            appendToast: true,
          });
        }
        this.page = { name: "" };
      }
    },
  },
};
</script>

<style lang="scss">
.list-fanpage-select > .multiselect--active {
  width: 100%;
  border: 1px solid #ededf0;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
.list-fanpage-select
  .multiselect__option {
    display: flex !important;
    align-items: center !important;
  }
  .option__image {
    height: 25px;
    width: 25px;
    object-fit: cover;
  }
  > .multiselect--active
  > .multiselect__content-wrapper
  > .multiselect__content
  > li
  > span {
  display: flex;
  align-items: center;
}

.list-fanpage-select
  > .multiselect--active
  > .multiselect__content-wrapper
  > .multiselect__content
  > li
  > span
  > .option__desc
  > .option__title {
  margin-left: 15px;
}
</style>

<style scoped>
button:focus {
  outline: unset;
}

.multiselect__placeholder {
  color: #000;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: none;
}

.multiselect--disabled > .multiselect__tags,
.multiselect--disabled > .multiselect__tags > .multiselect__single {
  background-color: #d2d9e0 !important;
  border: 1px solid #ced4da;
}

#drop-pages__BV_toggle_ + ul > li {
  padding: 5px;
}

.btn-secondary + ul > li > a {
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

.btn-secondary + .dropdown-menu {
  padding: 25.5px 25.1px;
  min-width: 100%;
}
.dropdown-item {
  padding: 0;
}
.dropdown-item > img {
  margin-right: 25.9px;
}

.dropdown-toggle::after {
  color: #dcdcdc;
}

.icon-fanpage {
  width: 45px;
  height: 45px;
}

#drop-pages > button {
  background-color: #ffffff !important;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  border: 1px solid #ededf0;
}

#btn-add-fanpage {
  margin-top: 30.68px;
  margin-bottom: 13.95px;
  width: 100%;
  height: 50px;
  background: var(--gray01);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.modal-content.shadow {
  border-radius: 7px;
}

.modal-dialog.modal-md.modal-dialog-centered {
  width: 453px;
}

#icon-close {
  cursor: pointer;
}

#title-modal,
.title-step {
  color: #000000;
  align-items: center;
  font-weight: 600;
  font-style: normal;
}

#title-modal {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px !important;
}

.title-step {
  font-size: 14px;
  line-height: 17px;
}
/* 20.82 */
small {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #8c8a97;
}

#content-title {
  margin-bottom: 31.68px;
  margin-top: 15px;
}

#title-modal {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px !important;
}
.login-again{
  display: flex;
  justify-content: right;
}
.login-again a{
  margin-top: 5px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;
  color: var(--gray01);

}
</style>
